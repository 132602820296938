.divSpanPerfilRequest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.statusProcess {
    width: 100%;
    margin: 10px;
    border-radius: 25px !important;
    background: #3FB079;
}

.statusProcess input {
    height: 100%;
}

.statusProcess div {
    border-radius: 25px;
}

.inputLabelStatusProcess {
    top: 9px !important;
    color: white !important;
}

.labelPerfilProcess {
    width: 40%;
    border: none;
}

.labelResultPerfilProcess {
    border: none;
}

.trPerfilProcess {
    width: 250%;
    margin: 10px 0;
}

.spanResultPerfilProcess {
    border-radius: 7px;
    background: #F9F9F9;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
}

.spanTitleInfoReseller {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #26398C;
}

.divTitleInfoReseller {
    margin: 20px 0;
}