.loginContainer {
    min-height: 100vh;
    background: var(--primaryColor);
    align-items: center;
    justify-content: center;
    display: flex;
}

.modalContainer {
    display: flex;
    height: 100%;
    background-color: transparent;
    border: none
}

.card {
    border: none !important
}

.loginImg {
    width: 100%;
}

.imgContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 100px;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 20px 0 10px;
    background-color: var(--secundaryColor);
    padding: 170px 20px;
}

.titleDiv {
    font-weight: 200;
    font-size: 46px;
}

.input {
    margin: 5px 0;
    width: 100%;
}

#buttonLogin {
    margin-top: 10px;
    width: 40%;
    height: 50px;
    color:  var(--secundaryColor);
    background: var(--primaryColor);
    border-color: transparent;
    font-size: 20px;
    font-weight: 200;
}

.buttonLogin {
    width: 100%;
}

.inputLog {
    margin: 5px 0;
    width: 100% !important;
    border-radius: 5px;
    height: 40%;
    padding: 0 5px;
    border: 1px solid #D3D3D3
}

.input-group {
    flex-direction: column;
}

.titleContainer {
    color: white;
    font-weight: 200;
    font-size: 46px;
    margin-bottom: 10px;
}

.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    font-weight: bold;
}