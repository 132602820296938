.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}