.dashContainer {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.css-ndns8y-MuiPaper-root-MuiCard-root {
    border: 1px solid rgb(197, 197, 197) !important
}

.css-d5yts0-MuiPaper-root-MuiCard-root {
    border: 1px solid rgb(197, 197, 197) !important
}

.divControllReserve {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 10%;
}

.divClientReserve {
    display: flex;
    background: #26398C;
    width: 20%;
    height: 50%;
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.divMonthTotalReserve {
    display: flex;
    background: #26398C;
    width: 20%;
    height: 50%;
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titleDivReserve {
    color: white;
    font-size: 20px;
    font-weight: 300;
}

.resultDivReserve {
    color: white;
    font-size: 50px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    text-align: center;
}

.divGridReserve {
    padding: 10px;
}

.filterDashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
}