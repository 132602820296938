.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}

.col2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 10px;
}

.divInputsImages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.divDetaisImage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.imgInput {
    background: #D9D9D9;
    width: 380px;
    height: 354px;
}

.divSelectedMarca {
    background-color: #D9D9D9;
    width: 68px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    flex-wrap: wrap;
    cursor: pointer;
}

.divSelectedMarca:hover {
    background-color: #3FB079 !important;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.containerSelectedMarca {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 90%;
}

.imgMarcaLogo {
    width: 70px;
}

.inputDetails {
    width: 100%;
    margin: 10px !important;
}

.inputDetails label {
    margin: 0 !important
}