
body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryColor: linear-gradient(210deg, #3FB079 0%, #25368C 100%);
  --secundaryColor: #3FB079;
}

.headerContainer {
    height: 55px;
    background-color: black;
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
}

.headerButtons {
    display: flex;
    justify-content: space-between;
    width: 15%;
}

.headerButton {
    text-decoration: none;
    color: white
}

.headerPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    height: 35px;
    width: 35px;
}

.headerPhoto p {
    font-weight: bold;
}

.iconBell {
    margin: 0 10px
}

.iconMail {
    margin: 0 10px
}

.iconContainer {
    margin: 0 10px;
    display: flex;
}

.lineVertical {
    margin: 0 20px 0 10px;
    align-items: center;
    font-size: 26px;
}

.img {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin: 0 10px
}

.userContainer {
    display: flex;
    align-items: center;
}

.d-flex {
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.Navbar {
    background: linear-gradient(190deg, #3FB079 0%, #25368C 100%);
    height: 80px;

}

.me-2 {
    background-color: #f0f0f0 !important;
    border: none !important;
}

.searchButton {
    color: #fff !important;
    background-color: #4e73df !important;
    border-color: #4e73df !important;
}

.userContainer-menu {
    background-color: transparent !important;
    border: none !important;
    width: 100%;
    color: black !important
}

.dropdown-header {
    left: -28px !important;
    padding-left: 23px !important;
}

.divHeaderBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40vw;
}

.navbar>.container-fluid {
    display: flex;
}

.btnMenuHeader {
    color: #FAB63D;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}

.btnMenuHeader:hover {
    text-decoration: none;
    color: #FAB63D;
}

.btnMenuHeader:focus {
    text-decoration: none;
    color: #FAB63D;
}

.userContainer-menu {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.divInfoUser {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.spanInforMornigh {
    color: white;
    font-weight: bold;
    height: 17px;
}

.spanInforUserName {
    color: white;

}

.dropdown-toggle {
    display: none
}

.iconHeader {
    font-size: 30px;
    color: #FAB63D
}
.dashContainer {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.css-ndns8y-MuiPaper-root-MuiCard-root {
    border: 1px solid rgb(197, 197, 197) !important
}

.css-d5yts0-MuiPaper-root-MuiCard-root {
    border: 1px solid rgb(197, 197, 197) !important
}

.divControllReserve {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 10%;
}

.divClientReserve {
    display: flex;
    background: #26398C;
    width: 20%;
    height: 50%;
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.divMonthTotalReserve {
    display: flex;
    background: #26398C;
    width: 20%;
    height: 50%;
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titleDivReserve {
    color: white;
    font-size: 20px;
    font-weight: 300;
}

.resultDivReserve {
    color: white;
    font-size: 50px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    text-align: center;
}

.divGridReserve {
    padding: 10px;
}

.filterDashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
}
.loginContainer {
    min-height: 100vh;
    background: var(--primaryColor);
    align-items: center;
    justify-content: center;
    display: flex;
}

.modalContainer {
    display: flex;
    height: 100%;
    background-color: transparent;
    border: none
}

.card {
    border: none !important
}

.loginImg {
    width: 100%;
}

.imgContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 100px;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 20px 0 10px;
    background-color: var(--secundaryColor);
    padding: 170px 20px;
}

.titleDiv {
    font-weight: 200;
    font-size: 46px;
}

.input {
    margin: 5px 0;
    width: 100%;
}

#buttonLogin {
    margin-top: 10px;
    width: 40%;
    height: 50px;
    color:  var(--secundaryColor);
    background: var(--primaryColor);
    border-color: transparent;
    font-size: 20px;
    font-weight: 200;
}

.buttonLogin {
    width: 100%;
}

.inputLog {
    margin: 5px 0;
    width: 100% !important;
    border-radius: 5px;
    height: 40%;
    padding: 0 5px;
    border: 1px solid #D3D3D3
}

.input-group {
    flex-direction: column;
}

.titleContainer {
    color: white;
    font-weight: 200;
    font-size: 46px;
    margin-bottom: 10px;
}

.errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: red;
    font-weight: bold;
}
.justify-content-center {
    background-color:  rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}

.link {
    color: white
}
.sidebar {
    width: 16rem;
    min-height: 100vh;
    /* text-transform: uppercase!important; */
}

.bg-gradient-primary {
    background-color: var(--primaryColor);
    /* background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%); */
    background-size: cover;
}

.accordion {
    overflow-anchor: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color: white;
}

.menuImg {
    margin: 10px 0;
    width: 30px;
    height: 30px;
}

.nav-item {
    margin: 0 15px;
}

.IconName {
    margin-left: 10px;
}

.lineMenu {
    margin: 10px;
    width: 100%;
}

.sidebar-heading {
    margin: 0 15px;
    font-weight: 800;
    font-size: .80rem;
}

.titleLogo {
    text-decoration: none;
    font-weight: 800;
    color: white;
}

.titleLogo:hover {
    color: white;
}

.button-menu {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none !important;
    color: white;
    text-decoration: none;
    padding: 0.375rem 0.75rem;
}

.button-accordion {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none !important;
    color: white;
    text-decoration: none;
    padding: 0.375rem 0.75rem;
}

.button-accordion:hover {
    background-color: #3e5cb8;
    color: white;
}

.button-menu:hover {
    background-color: #3e5cb8;
    color: white;
}

.drop-menu {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none !important;
}

.drop-menu button {
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.drop-menu button:hover {
    background-color: #3e5cb8;
}

.show>.btn-secondary.dropdown-toggle {
    background: #3e5cb8 !important;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.icons {
    margin-right: 5px;
    color:  var(--secundaryColor);
}

.subMenu {
    background-color: var(--secundaryColor) !important;
    color: black !important;
}

.super-colors {
    background-color: var(--secundaryColor) !important;
    transform: translate(225px, 0px) !important;
}

.super-colors-accordion {
    background-color: var(--secundaryColor) !important;
    transform: translate(55px, 0px) !important;
}

.sidebarAccordion {
    min-height: 100vh;
}

.submenu {
    left: -15px !important;
}

.submenu-accordion {
    left: 106px !important;
}

body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '\1F447';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '\261D\FE0F';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.formAction {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.containerSelectAction {
  margin: 0 10px;
  width: 230px;
}

.selectAction {
  display: flex !important;
}


.cancelButton {
  margin: 10px !important
}

.true {
  background-color: green !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.false {
  background-color: red !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.cancelBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#demo-simple-select-label {
  margin-left: 10px
}

.imgGif {
  width: 24px;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.colunmLabel {
  font-weight: bold !important;
}

.inputMask {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.perfilAction {
  font-size: 18px;
  color: #4e73df !important;
}

.buttonActionTable {
  margin: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}

.alertError {
    font-weight: bold !important;
}
.BaseContainer {
    display: flex;
}

.CenterContainer {
    width: 100%;
}

@media (max-width: 1110px) {
    .CenterContainer {
        width: 80%;
    }
}

.orderedContainer {
    min-height: 89vh;
    max-height: 100vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.orderedContainerGeratorImage {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.titleAndButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1650px) {
    .orderedContainer {
        min-height: 190vh;
    }
}

.button {
    margin: 0 10px;
    color: #fff;
    background: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor) !important;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 180px
}

.buttonSecundary {
    margin: 10px 0;
    color: #fff;
    background-color: var(--secundaryColor) !important;
    border: 1px solid var(--secundaryColor) !important;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 210px;
}

.container2itens {
    display: flex;
    flex-direction: row;
    margin: 5px 0 !important
}

.inputCadastro {
    width: 100% !important;
    border-radius: 5px;
    height: 40px;
    border: 1px solid gray;
    padding: 0 5px;
}

.imgTable {
    width: 200px;
}

.MuiMenu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MuiMenu-list li {
    width: 100%;
    display: flex;
    justify-content: center;
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}

.col2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 10px;
}

.divInputsImages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.divDetaisImage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.imgInput {
    background: #D9D9D9;
    width: 380px;
    height: 354px;
}

.divSelectedMarca {
    background-color: #D9D9D9;
    width: 68px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    flex-wrap: wrap;
    cursor: pointer;
}

.divSelectedMarca:hover {
    background-color: #3FB079 !important;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.containerSelectedMarca {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 90%;
}

.imgMarcaLogo {
    width: 70px;
}

.inputDetails {
    width: 100%;
    margin: 10px !important;
}

.inputDetails label {
    margin: 0 !important
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}

body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '\1F447';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '\261D\FE0F';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.formAction {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.containerSelectAction {
  margin: 0 10px;
  width: 230px;
}

.selectAction {
  display: flex !important;
}


.cancelButton {
  margin: 10px !important
}

.true {
  background-color: green !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.false {
  background-color: red !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.cancelBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#demo-simple-select-label {
  margin-left: 10px
}

.imgGif {
  width: 24px;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.colunmLabel {
  font-weight: bold !important;
}

.inputMask {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.perfilAction {
  font-size: 18px;
  color: #4e73df !important;
}

.buttonActionTable {
  margin: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerAllArts {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.containerArt {
  width: 230px;
  margin: 10px;
  height: 7%;
}

.imageArt {
  width: 230px;
  height: 166px;
  border-radius: 25px;
  background-color: #D9D9D9;
}

.containerContect {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.btnEditArt {
  height: 30px;
  width: 30px;
  border-radius: 25px;
  border: none;
  background: linear-gradient(210deg, #3FB079 0%, #25368C 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconEditArt {
  color: white;
}

.titleArt {
  width: 80%;
}

.imgArt {
  border-radius: 25px;
  width: 230px;
  height: 210px
}

.filterArts {
  display: flex;
  justify-content: start;
  align-items: center;
}

.filterArtName {
  width: 25%;
  margin: 10px;
}

.filterArtName div {
  border-radius: 25px;
}

.filterArtCategory {
  width: 100%;
  margin: 10px;
  border-radius: 25px !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth {
  width: 25%;
}

.MuiFormControl-root.MuiFormControl-fullWidth label {
  top: 11px;
}

.filterArtCategory input {
  height: 100%;
}

.spanPost {
  max-width: 41ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.divPost {
  display: flex;
  height: 14vh;
}
.alertModal {
    position: fixed;
    width: 50%;
    top: 10px;
    right: 10px;
    z-index: 9;
}
body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '\1F447';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '\261D\FE0F';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.formAction {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.containerSelectAction {
  margin: 0 10px;
  width: 230px;
}

.selectAction {
  display: flex !important;
}


.cancelButton {
  margin: 10px !important
}

.true {
  background-color: green !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.false {
  background-color: red !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.cancelBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#demo-simple-select-label {
  margin-left: 10px
}

.imgGif {
  width: 24px;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.colunmLabel {
  font-weight: bold !important;
}

.inputMask {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.perfilAction {
  font-size: 18px;
  color: #4e73df !important;
}

.buttonActionTable {
  margin: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btnViewer {
  border: 1px solid #4e73df;
  border-radius: 25px;
  font-size: 14px;
  width: 100px;
  font-weight: bold;
  text-decoration: none;
}

.moduleDiv {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 10px;
  align-items: center;
  border-radius: 25px;
}

.columnModule {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputMaskPainel {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.00rem;
  color: rgba(0, 0, 0, 0.87);
}

.inputMaskPainel2 {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.00rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  width: 100%;
}

.modal-header {
  background: var(--primaryColor);
  color: white;
}
.PageNotFoundContainer {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

@media only screen and (max-width: 1650px) {
    .PageNotFoundContainer {
        min-height: 92vh;
    }
}
.divStatus {
    width: 100px;
    color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataReseler {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerImgReseler {
    width: 186px;
    height: 186px;
    background: #D9D9D9;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusReseler {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 20vh;
    padding-top: 20px;
    flex-direction: column;
}

.contenctReseler {
    width: 25%;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 20vh;
}

.modulesReseler {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tableInvoice {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgReserve {
    width: 100%;
    padding: 10px;
}

.btnModule {
    background-color: transparent;
    border: none
}

.currentValuePerfilResale {
    color: black;
    font-size: 15px;
    background-color: transparent;
    border: none;
}

body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '\1F447';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '\261D\FE0F';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.formAction {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.containerSelectAction {
  margin: 0 10px;
  width: 230px;
}

.selectAction {
  display: flex !important;
}


.cancelButton {
  margin: 10px !important
}

.true {
  background-color: green !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.false {
  background-color: red !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.cancelBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#demo-simple-select-label {
  margin-left: 10px
}

.imgGif {
  width: 24px;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.colunmLabel {
  font-weight: bold !important;
}

.inputMask {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.perfilAction {
  font-size: 18px;
  color: #4e73df !important;
}

.buttonActionTable {
  margin: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btnViewer {
  border: 1px solid #4e73df;
  border-radius: 25px;
  font-size: 14px;
  width: 100px;
  font-weight: bold;
  text-decoration: none;
}

.moduleDiv {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 10px;
  align-items: center;
  border-radius: 25px;
}

.columnModule {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}

.divRequestChamados {
    width: 188px;
    height: 165px;
    top: 202px;
    left: 323px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 27px;
    ;
    background: #8B8B8B;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    text-decoration: none;
    padding-top: 13px;
}

.spanRequest {
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    color: #26398C;
}

hr {
    background: linear-gradient(200deg, #3FB079 0%, #25368C 100%);
    height: 3px;
    width: 100%;
    border-top: transparent !important;
    opacity: 100% !important;
}

.btnRquestChamado {
    width: 190px;
    height: 42px;
    top: 700px;
    left: 323px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 21px;
    border: 1px;
    ;
    border: 1px solid #3FB079;
    background: transparent;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #3FB079;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerAllRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.divSpanRequest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.containerDivRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
}

.containerDivBtnRequest {
    margin: 0 10px
}

.imgModule {
    padding: 10px;
}

.divSpanNameModule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.spanNameModule {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: white;
}

.linkRequestChamado {
    color: #3FB079;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkRequestChamado:hover {
    color: #3FB079;
    text-decoration: none;
}

.iconReseller {
    font-size: 60px;
    padding: 10px;
    color: #fff;
    margin-bottom: 5px;
}
.divLinkToMore {
    width: 110.39px;
    height: 27px;
    top: 267px;
    left: 942.01px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 17px;
    border: 1px;
    ;
    background: transparent;
    border: 1px solid #1A1B39;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkToMore {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #1A1B39;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkToMore:hover {
    color: #1A1B39;
    text-decoration: none;
}

.titleHeaderTable {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18.75px !important;
    text-align: left !important;
    color: #26398C !important;
}
.divSpanPerfilRequest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.statusProcess {
    width: 100%;
    margin: 10px;
    border-radius: 25px !important;
    background: #3FB079;
}

.statusProcess input {
    height: 100%;
}

.statusProcess div {
    border-radius: 25px;
}

.inputLabelStatusProcess {
    top: 9px !important;
    color: white !important;
}

.labelPerfilProcess {
    width: 40%;
    border: none;
}

.labelResultPerfilProcess {
    border: none;
}

.trPerfilProcess {
    width: 250%;
    margin: 10px 0;
}

.spanResultPerfilProcess {
    border-radius: 7px;
    background: #F9F9F9;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
}

.spanTitleInfoReseller {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #26398C;
}

.divTitleInfoReseller {
    margin: 20px 0;
}

body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '\1F447';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '\261D\FE0F';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.formAction {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.containerSelectAction {
  margin: 0 10px;
  width: 230px;
}

.selectAction {
  display: flex !important;
}


.cancelButton {
  margin: 10px !important
}

.true {
  background-color: green !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.false {
  background-color: red !important;
  border: none !important;
  padding: 10px 20px !important;
  color: white !important;
}

.cancelBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#demo-simple-select-label {
  margin-left: 10px
}

.imgGif {
  width: 24px;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.colunmLabel {
  font-weight: bold !important;
}

.inputMask {
  border: none;
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.perfilAction {
  font-size: 18px;
  color: #4e73df !important;
}

.buttonActionTable {
  margin: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerAllArts {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.containerArt {
  width: 230px;
  margin: 10px;
  height: 7%;
}

.imageArt {
  width: 230px;
  height: 166px;
  border-radius: 25px;
  background-color: #D9D9D9;
}

.containerContect {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.btnEditArt {
  height: 30px;
  width: 30px;
  border-radius: 25px;
  border: none;
  background: linear-gradient(210deg, #3FB079 0%, #25368C 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconEditArt {
  color: white;
}

.titleArt {
  width: 80%;
}

.imgArt {
  border-radius: 25px;
  width: 230px;
  height: 210px
}

.filterArts {
  display: flex;
  justify-content: start;
  align-items: center;
}

.filterArtName {
  width: 25%;
  margin: 10px;
}

.filterArtName div {
  border-radius: 25px;
}

.filterArtCategory {
  width: 100%;
  margin: 10px;
  border-radius: 25px !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth {
  width: 25%;
}

.MuiFormControl-root.MuiFormControl-fullWidth label {
  top: 11px;
}

.filterArtCategory input {
  height: 100%;
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}

.col2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 10px;
}

.divInputsImages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.divDetaisImage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.imgInput2 {
    background: #D9D9D9;
    width: 200px;
    height: 200px;
}

.divSelectedMarca {
    background-color: #D9D9D9;
    width: 68px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    flex-wrap: wrap;
    cursor: pointer;
}

.divSelectedMarca:hover {
    background-color: #3FB079 !important;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.containerSelectedMarca {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 90%;
}

.imgMarcaLogo {
    width: 70px;
}

.inputDetails {
    width: 100%;
    margin: 10px !important;
}

.inputDetails label {
    margin: 0 !important
}
.itemDireitaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.itemEsquerdaProfile {
    margin-right: 5px !important;
    width: 50%;
}

.imgProfile {
    width: 300px;
    height: 300px;
    border: 1px solid gray;
    margin: 10px;
}
