.divStatus {
    width: 100px;
    color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataReseler {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerImgReseler {
    width: 186px;
    height: 186px;
    background: #D9D9D9;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusReseler {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 20vh;
    padding-top: 20px;
    flex-direction: column;
}

.contenctReseler {
    width: 25%;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 20vh;
}

.modulesReseler {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tableInvoice {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgReserve {
    width: 100%;
    padding: 10px;
}

.btnModule {
    background-color: transparent;
    border: none
}

.currentValuePerfilResale {
    color: black;
    font-size: 15px;
    background-color: transparent;
    border: none;
}