.containerRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}

.divRequestChamados {
    width: 188px;
    height: 165px;
    top: 202px;
    left: 323px;
    gap: 0px;
    border-radius: 27px;
    ;
    background: #8B8B8B;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    text-decoration: none;
    padding-top: 13px;
}

.spanRequest {
    font-size: 32px;
    font-weight: 800;
    line-height: 37.5px;
    text-align: left;
    color: #26398C;
}

hr {
    background: linear-gradient(200deg, #3FB079 0%, #25368C 100%);
    height: 3px;
    width: 100%;
    border-top: transparent !important;
    opacity: 100% !important;
}

.btnRquestChamado {
    width: 190px;
    height: 42px;
    top: 700px;
    left: 323px;
    gap: 0px;
    border-radius: 21px;
    border: 1px;
    ;
    border: 1px solid #3FB079;
    background: transparent;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #3FB079;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerAllRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.divSpanRequest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.containerDivRequestChamados {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
}

.containerDivBtnRequest {
    margin: 0 10px
}

.imgModule {
    padding: 10px;
}

.divSpanNameModule {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.spanNameModule {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: white;
}

.linkRequestChamado {
    color: #3FB079;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkRequestChamado:hover {
    color: #3FB079;
    text-decoration: none;
}

.iconReseller {
    font-size: 60px;
    padding: 10px;
    color: #fff;
    margin-bottom: 5px;
}