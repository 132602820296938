.headerContainer {
    height: 55px;
    background-color: black;
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
}

.headerButtons {
    display: flex;
    justify-content: space-between;
    width: 15%;
}

.headerButton {
    text-decoration: none;
    color: white
}

.headerPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    height: 35px;
    width: 35px;
}

.headerPhoto p {
    font-weight: bold;
}

.iconBell {
    margin: 0 10px
}

.iconMail {
    margin: 0 10px
}

.iconContainer {
    margin: 0 10px;
    display: flex;
}

.lineVertical {
    margin: 0 20px 0 10px;
    align-items: center;
    font-size: 26px;
}

.img {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin: 0 10px
}

.userContainer {
    display: flex;
    align-items: center;
}

.d-flex {
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.Navbar {
    background: linear-gradient(190deg, #3FB079 0%, #25368C 100%);
    height: 80px;

}

.me-2 {
    background-color: #f0f0f0 !important;
    border: none !important;
}

.searchButton {
    color: #fff !important;
    background-color: #4e73df !important;
    border-color: #4e73df !important;
}

.userContainer-menu {
    background-color: transparent !important;
    border: none !important;
    width: 100%;
    color: black !important
}

.dropdown-header {
    left: -28px !important;
    padding-left: 23px !important;
}

.divHeaderBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40vw;
}

.navbar>.container-fluid {
    display: flex;
}

.btnMenuHeader {
    color: #FAB63D;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}

.btnMenuHeader:hover {
    text-decoration: none;
    color: #FAB63D;
}

.btnMenuHeader:focus {
    text-decoration: none;
    color: #FAB63D;
}

.userContainer-menu {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.divInfoUser {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.spanInforMornigh {
    color: white;
    font-weight: bold;
    height: 17px;
}

.spanInforUserName {
    color: white;

}

.dropdown-toggle {
    display: none
}

.iconHeader {
    font-size: 30px;
    color: #FAB63D
}