.BaseContainer {
    display: flex;
}

.CenterContainer {
    width: 100%;
}

@media (max-width: 1110px) {
    .CenterContainer {
        width: 80%;
    }
}

.orderedContainer {
    min-height: 89vh;
    max-height: 100vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.orderedContainerGeratorImage {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

.titleAndButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1650px) {
    .orderedContainer {
        min-height: 190vh;
    }
}

.button {
    margin: 0 10px;
    color: #fff;
    background: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor) !important;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 180px
}

.buttonSecundary {
    margin: 10px 0;
    color: #fff;
    background-color: var(--secundaryColor) !important;
    border: 1px solid var(--secundaryColor) !important;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 210px;
}

.container2itens {
    display: flex;
    flex-direction: row;
    margin: 5px 0 !important
}

.inputCadastro {
    width: 100% !important;
    border-radius: 5px;
    height: 40px;
    border: 1px solid gray;
    padding: 0 5px;
}

.imgTable {
    width: 200px;
}

.MuiMenu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MuiMenu-list li {
    width: 100%;
    display: flex;
    justify-content: center;
}