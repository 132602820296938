.PageNotFoundContainer {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

@media only screen and (max-width: 1650px) {
    .PageNotFoundContainer {
        min-height: 92vh;
    }
}