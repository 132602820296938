.divLinkToMore {
    width: 110.39px;
    height: 27px;
    top: 267px;
    left: 942.01px;
    gap: 0px;
    border-radius: 17px;
    border: 1px;
    ;
    background: transparent;
    border: 1px solid #1A1B39;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkToMore {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #1A1B39;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkToMore:hover {
    color: #1A1B39;
    text-decoration: none;
}

.titleHeaderTable {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18.75px !important;
    text-align: left !important;
    color: #26398C !important;
}